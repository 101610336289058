import React from "react"
import { Auth0ProviderWrapper } from "./src/components/root-elements/auth0"
import { QueryClientProviderWrapper } from "./src/components/root-elements/react-query"
import "./src/styles/global.css"

export const wrapRootElement = ({ element }) => {
 return (
  <Auth0ProviderWrapper>
    <QueryClientProviderWrapper>
    {element}
    </QueryClientProviderWrapper>
  </Auth0ProviderWrapper>
 )
}