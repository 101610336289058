exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-client-new-js": () => import("./../../../src/pages/client/new.js" /* webpackChunkName: "component---src-pages-client-new-js" */),
  "component---src-pages-estimates-[id]-edit-js": () => import("./../../../src/pages/estimates/[id]/edit.js" /* webpackChunkName: "component---src-pages-estimates-[id]-edit-js" */),
  "component---src-pages-estimates-[id]-js": () => import("./../../../src/pages/estimates/[id].js" /* webpackChunkName: "component---src-pages-estimates-[id]-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-research-estimate-js": () => import("./../../../src/pages/research/estimate.js" /* webpackChunkName: "component---src-pages-research-estimate-js" */),
  "component---src-pages-researcher-new-js": () => import("./../../../src/pages/researcher/new.js" /* webpackChunkName: "component---src-pages-researcher-new-js" */)
}

