import React from "react"
import { Auth0Provider } from "@auth0/auth0-react"
import { navigate } from "gatsby"

const onRedirectCallback = (appState) => {
  navigate(appState?.returnTo || '/', { replace: true })
}

export const Auth0ProviderWrapper = ({ children }) => {
	return (
		<Auth0Provider
			domain={process.env.GATSBY_AUTH0_DOMAIN}
			clientId={process.env.GATSBY_AUTH0_CLIENT_ID}
			redirectUri={process.env.GATSBY_SITE_URL}
			onRedirectCallback={onRedirectCallback}
			>
			{children}
		</Auth0Provider>
 )
}